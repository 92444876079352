import React, { useState, useContext, useEffect } from 'react';
import { SafeAreaView, ActivityIndicator, StyleSheet, Text, View, StatusBar, TextInput, KeyboardAvoidingView, ScrollView, Platform} from 'react-native';
import  { PostContext } from '../context/PostContext';
import Button from '../components/Button';
import { authFetch } from '../util/authUtil'
import {API_URL} from "../Config"
import {Picker} from '@react-native-picker/picker'
import { SeparatorSpace } from '../components/Form';
import { Image } from 'expo-image';
import HeaderTextButton from '../components/HeaderTextButton';
const PostFinishScreen = ({ route, navigation }) => {

    const [message,setMessage] = useState(null);
    const { post, setPost } = useContext(PostContext);
    const [cuisine, setCuisine] = useState("");
    const [comments, setComments] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    useEffect(() => {

        console.log(post);
        navigation.setOptions({
            headerRight: () => (
                <View style={{ flexDirection: 'row',alignItems: 'center',justifyContent: 'center', paddingLeft: 20}}>
                    <HeaderTextButton title="Post" disabled={isSubmitting}  size={25} onPress={_onNextPress}/>
                </View>
            ),
        });
    }, [navigation,isSubmitting]);

    const _onNextPress = async (e) => {
        setIsSubmitting(true);
        console.log(post);
        const formData  = new FormData();

        if (post && post.image && post.image.base64) {
            formData.append('file', post.image.base64);
        }

      
        formData.append('place', post.place_id ? post.place_main_text : post.place_entered);
        formData.append('place_secondary_text', post.place_secondary_text);
        formData.append('place_id', post.place_id);
        formData.append('place_latitude', post.place_latitude);
        formData.append('place_longitude', post.place_longitude);
        formData.append('cuisine', cuisine);
        formData.append('comments', comments);
        
        try {
            const response = await authFetch(`${API_URL}/post`, {
                method: 'POST',
                body: formData,
            });
            const json = await response.json();
            if (response.status === 201) {
               
               setPost({ ...post, last_id: json.id});

               navigation.navigate('Posts');
            } else {
                setMessage(json && json.message ? json.message : "Error adding post please contact site adminstrator");
            }
            
        } catch (error) {
           setMessage("Error adding post please contact site adminstrator");
        };
        navigation.setParams({ ...route.params, isSubmitting: false  });
        setIsSubmitting(false);
    }
    const _renderImage = () => (
        <Image contentFit="contain" contentPosition="left" source={{ uri: post.image.uri }} style={styles.image} />
    );
    return (
        <SafeAreaView style={styles.container}>
        {/* https://stackoverflow.com/questions/38137388/scroll-view-inside-view-not-working-react-native */}
        <ScrollView contentContainerStyle={{flexGrow: 1 , margin: 10 }}>
            <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"}>
                { post && <Text>{post.place_id ? post.place_main_text : post.place_entered}</Text>}
                <SeparatorSpace height={5}/>
                { isSubmitting && <ActivityIndicator size="large" /> }
                { post && post.image && _renderImage()}
                <SeparatorSpace height={5}/>

                {/* <Text>What cuisine are you eating?</Text>
                <Picker
                    selectedValue={cuisine}
                    onValueChange={(itemValue, itemIndex) =>
                        setCuisine(itemValue)
                    }>
                    <Picker.Item label="American" value="American" />
                    <Picker.Item label="Chinese" value="Chinese" />
                    <Picker.Item label="Mexican" value="Mexican" />
                    <Picker.Item label="Other" value="Other" />
                </Picker> */}
                <SeparatorSpace height={5}/>
                <View style={{ marginBottom: 5 }}><Text>Comments(optional)</Text></View>
                {/* https://github.com/facebook/react-native/issues/16826 */}
                    <TextInput style={{ borderColor: 'gray', borderWidth: 1, borderRadius: 4  }}
                    multiline={true}
                    scrollEnabled={false} 
                    numberOfLines={4}
                    minHeight={150}
                    //maxHeight={250}
                    onChangeText={setComments}
                    value={comments}/>

                <SeparatorSpace height={15}/>
                {/* <Button variant="primary" onPress={onNextPress} disabled={isSubmitting} title="Submit Post" style={{alignSelf: 'center'}}/> */}
                <SeparatorSpace height={15}/>
                <Text style={styles.error}>{message}</Text>
                <SeparatorSpace height={15}/>
                {/* </ScrollView> */}
            </KeyboardAvoidingView>
        </ScrollView>
    </SafeAreaView>)
}
const styles = StyleSheet.create({
    // container: {
    //   //flex: 1,
    //   //alignItems: 'stretch',
    //   marginTop: StatusBar.currentHeight || 0,
    //   backgroundColor: '#fff'
    // },
    container: {
        flex: 1,
        alignItems: 'stretch',
        marginTop: StatusBar.currentHeight || 0,
        backgroundColor: '#fff'
    },
    image: {
        //alignSelf: 'center',
        //flex: 1,
        //width: 100,
        height: 100,
     //   width: '100%',
    //   width: '50',
    //   height: '50'
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    button: {
      alignItems: "center",
      backgroundColor:'#4b955e',
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: '#fff',
      webkitUserSelect:'none',
      msUserSelect: 'none',
      userSelect: 'none'
  },
  error: {
      color: 'red',
      alignSelf: 'center'
  }
});
  
export default PostFinishScreen;