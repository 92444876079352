import React, { useState } from 'react';
import { StatusBar } from 'expo-status-bar';
import { Platform, useColorScheme, View} from 'react-native';

import { SafeAreaProvider } from 'react-native-safe-area-context';
import Navigation from './navigation/Navigation';
import useCachedResources from './hooks/useCachedResources';
import {PostContextProvider} from './context/PostContext';

// import * as Location from 'expo-location';

// import Geolocation from 'react-native-geolocation-service';
// import Geolocation from '@react-native-community/geolocation';

// Geolocation.getCurrentPosition(info => console.log(info));


// import Geolocation from '@react-native-community/geolocation';

// Geolocation.getCurrentPosition(info => console.log(info));


export default function App() {

    const isLoadingComplete = useCachedResources();
    const colorScheme = useColorScheme();
    if (!isLoadingComplete) 
        return null;
  
    return (
        <SafeAreaProvider>
             <PostContextProvider>
                {/* <View style={{ 
                    flexDirection: 'column', 
                    alignItems: 'stretch',
                    justifyContent: 'center',
                    marginLeft: '20%',
                    marginRight: '20%',
                    //maxWidth: 640,
                    flex: '1 1 0' }}>
                   
                    
                </View> */}
                <Navigation colorScheme={colorScheme} />
             </PostContextProvider>
            <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
        </SafeAreaProvider>
    );
   
}
