import React, { useRef, useContext, useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, View, Text, StatusBar} from 'react-native';
import  { PostContext, postInitialState } from '../context/PostContext';
import { SeparatorSpace } from '../components/Form';
import HeaderTextButton from '../components/HeaderTextButton';
import ComingSoon from '../components/ComingSoon';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { HOME_SCREEN_NAME } from '../constants/Global';
import {GOOGLE_MAPS_API_KEY} from "../Config"
import { getAuthHeaders } from '../util/authUtil'
import {API_URL} from "../Config"

const IMAGE_WIDTH = 1080;
const IMAGE_COMPRESS = 1;

const PostPlaceScreen = ({ route, navigation }) => {

    const { post, setPost } = useContext(PostContext);
    //const [ myPost, setMyPost ] = useState(postInitialState);
    const [ readyForNext, setReadyForNext] = useState(false);
    const ref = useRef();
    useEffect(() => {
        
        navigation.setOptions({
            headerRight: () => (
                <View style={{ flexDirection: 'row',alignItems: 'center',justifyContent: 'center', paddingLeft: 20}}>
                    <HeaderTextButton title="Next" size={25} onPress={_onNextPress}/>
                </View>
            ),
        });


      //ref.current?.setAddressText('');
     // ref.current?.getCurrentLocation();
        //Had to add post below to deal with issue of state not updating with navigation changes
    }, [post]);
    const _onCancelPress = (e) => {
        navigation.navigate(HOME_SCREEN_NAME);
    }
    
    const _onNextPress = (e) => {
       const enteredText = ref.current.getAddressText();
       setPost({  ...post,
        place_entered: enteredText
       });
       navigation.navigate('PostFinish');

    }

    const _onPlacesPress = (data, details = null) => {
      setPost({...post,
        place_main_text: data.structured_formatting.main_text,
        place_id: data.place_id,
        place_secondary_text: data.structured_formatting.secondary_text,
        place_latitude: details.geometry.location.lat,
        place_longitude: details.geometry.location.lng
      });
    }

    const authHeaders = getAuthHeaders();
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.container}>
        <GooglePlacesAutocomplete
            ref={ref}
           // autoFillOnNotFound={true}
         //   currentLocation={true}
        //currentLocationLabel='Current location'
            requestUrl={{
            //useOnPlatform: 'web', // or "all"
            //nearbyPlacesAPI: 'GoogleReverseGeocoding',

            //Bug found here:  need to find workaround.  It looks like its using the gelocation of the server.
            /*   https://github.com/FaridSafi/react-native-google-places-autocomplete/pull/836/files  
            */
            //nearbyPlacesAPI: "GooglePlacesSearch",
            useOnPlatform: 'all', // or "all"
                url:  `${API_URL}/maps/api`,
                //'https://cors-anywhere.herokuapp.com/https://maps.googleapis.com/maps/api', // or any proxy server that hits https://maps.googleapis.com/maps/api
                headers: authHeaders
            }}
            
           
            placeholder='Search for or type a new place'
            onFail={(error) => console.error(error)}
            onNotFound={() => console.log('Not found')}
            fetchDetails={true}
            onPress={_onPlacesPress}
         
            query={{
                key: GOOGLE_MAPS_API_KEY,
                language: 'en',
            }}
            styles={{
              // textInputContainer: {
              //   backgroundColor: 'grey',
              // },
              textInput: {
                margin: '3px',
                border: '1px solid #c8c7cc',
                height: 38,
                color: '#5d5d5d',
                fontSize: 16,
              },
              predefinedPlacesDescription: {
                color: '#1faadb',
              },
            }}
        />
        </View>
    </SafeAreaView>)
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'stretch',
      marginTop: StatusBar.currentHeight || 0,
      backgroundColor: '#fff'
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    button: {
      alignItems: "center",
      backgroundColor:'#4b955e',
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: '#fff',
      webkitUserSelect:'none',
      msUserSelect: 'none',
      userSelect: 'none'
    },

    image: {
     flex: 1,
     width: '100%',
    //   paddingLeft: 10,
    //   paddingRight: 10,

      // marginLeft: 10,
      // marginRight: 10
      //backgroundColor: '#0553',
    },

  });
  
export default PostPlaceScreen;