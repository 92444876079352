import React, { useEffect,useState } from 'react';
import { Platform, StyleSheet, Text, SafeAreaView, View, TextInput } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Button from '../components/Button';
import { setSecureToken , setSecureRefreshToken } from '../util/authUtil';
import { PASSWORD_RESET_CHANGE_SCREEN_NAME } from '../constants/Global';
import {API_URL} from "../Config"

export default function PasswordResetVerifyScreen({ navigation, route }) {

  const [message,setMessage] = useState(null);
  const [code, setCode] = useState('');
  const onSubmitPress = async ()=> {
      
      if (code <= 0) return setMessage("Code is required field");
      const payload = {
          "code": code
      };
      try {
          const response = await fetch(`${API_URL}/password-reset-verify`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
          });
          
          if (response.status === 200) {
            const json = await response.json();
            setSecureToken(json.token);
            navigation.navigate(PASSWORD_RESET_CHANGE_SCREEN_NAME);
          } else {
              setMessage(json.message);
          }
         
      } catch (error) {
          console.log(error);
          setMessage("unable to complete verification");
      };
    }

    return (
      <SafeAreaView style={styles.container}>
 
        <View style={styles.container}>
            <Text style={styles.title}>Password Change Email Verify</Text>
            <TextInput
                style={styles.input}
                placeholder="Enter verification code"
                onChangeText={(text) => setCode(text)}
                value={code}
            />
          
           <Button variant="primary" onPress={onSubmitPress} title="Submit"/>
           <Text style={styles.error}>{message}</Text>
        </View>
        <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
      </SafeAreaView>
    );
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
    },
    title: {
      fontSize: 24,
      marginBottom: 16,
    },
    input: {
      width: '100%',
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
      borderRadius: 4,
      marginBottom: 16,
      paddingHorizontal: 8,
    },
    error: {
         color: 'red'
    }
  });

  
