import React, { useState, useEffect } from 'react';
import { Platform, StyleSheet, Text, View ,SafeAreaView, Image } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Button from '../components/Button';
import { SeparatorSpace } from '../components/Form';
import { userIsAuthenticated } from '../util/authUtil';
import { HOME_SCREEN_NAME } from '../constants/Global';

const logo = require('../assets/images/foodeez-logo.png');

export default function WelcomeScreen({ navigation }) {
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async () => {
        const isAuth = await userIsAuthenticated();
        if (isAuth) {
            navigation.navigate(HOME_SCREEN_NAME);
        }
    }, []);
    return unsubscribe;
  }, [navigation]);

  const onSignInPress = (e) => {
    navigation.navigate('Login');
  }

  const onSignUpPress = (e) => {
    navigation.navigate('SignUp');
  }
  return (
    <SafeAreaView style={styles.container}>
      <View style={{ flex: 2 }}></View>
      <Image style={styles.logo} source={logo} />
      <Text>Where Food Connects Friends</Text>
      <View style={{ flex: 3 }}></View>
      <View style={{ alignSelf: 'stretch'}}>
         <Button variant="primary" onPress={onSignUpPress} title="Get Started"/>
      </View>
      <SeparatorSpace height={5}/>
      <View style={{ alignSelf: 'stretch'}}>
          <Button variant="secondary" onPress={onSignInPress} title="Already have an account? Login"/>
      </View>
     
      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <SeparatorSpace height={15}/>
      <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
    </SafeAreaView>
  );
} 

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 15,
    marginRight: 15
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  logo: {
    width: 200,
    height: 200
  }
});
