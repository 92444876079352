import { createContext, useState, useReducer } from 'react';

const postInitialState = {
  image: null,
  cuisine: null,
  place_entered: null,
  place_id: null,
  place_main_text: null,
  place_secondary_text: null,
  place_latitude: null,
  place_longitude: null,
  last_id: null
};
const PostContext = createContext({
    setPost: () => {}
});

const PostContextProvider = ({ children }) => {
    const [post, setPost] = useState(postInitialState);
    return (
      <PostContext.Provider value={{ post, setPost }}>
        {children}
      </PostContext.Provider>
    );
};

const PostContextConsumer = PostContext.Consumer;
export  { PostContextProvider, PostContextConsumer , PostContext, postInitialState } ;
