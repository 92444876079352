import React, { useState, useContext, useEffect } from 'react';
import { SafeAreaView, StyleSheet, View,  StatusBar} from 'react-native';
import { Image } from 'expo-image';
import { manipulateAsync, FlipType, SaveFormat } from 'expo-image-manipulator';

import * as ExpoImagePicker from 'expo-image-picker';

import  { PostContext, postInitialState } from '../context/PostContext';
import Button from '../components/Button';
import { SeparatorSpace } from '../components/Form';
import HeaderTextButton from '../components/HeaderTextButton';

const IMAGE_WIDTH = 1080;
const IMAGE_COMPRESS = 1;

const PostStartScreen = ({ route, navigation }) => {

    const { post, setPost } = useContext(PostContext);
    const [ready, setReady] = useState(false);
    const [status, requestPermission] = ExpoImagePicker.useCameraPermissions();

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', async () => {
            setPost({ ...postInitialState, last_id: post.last_id});
        }, []);
        return unsubscribe;
    }, [navigation]);

    useEffect(() => {
        navigation.setOptions({
            headerRight: () => (
                <View style={{ flexDirection: 'row',alignItems: 'center',justifyContent: 'center', paddingLeft: 20}}>
                    <HeaderTextButton title="Next" size={25} onPress={_onNextPress}/>
                </View>
            ),
        });
    }, [navigation]);


    const _onNextPress = (e) => {

      navigation.navigate('PostPlace');

      // console.log(post);
      //  if (post.image !== null) {
      //   navigation.navigate('PostPlace');
      //  } else {
       
      //     console.log("No image selected");
      //  }
     
    }
    const _launchCamera = async () => {
        console.log(status);
        await requestPermission();
        let result = await ExpoImagePicker.launchCameraAsync({
            mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
            allowsEditing: false,
            //aspect: [4, 3],
            quality: 1,
            base64: true
        });

        if (!result.canceled) {
            await _processImage(result.assets[0]);
        }
    };
    const _launchMediaLibrary = async () => {
        let result = await ExpoImagePicker.launchImageLibraryAsync({
          mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
          allowsEditing: false,
          //aspect: [4, 3],
          quality: 1,
          base64: true
        });

        if (!result.canceled) {
           await _processImage(result.assets[0]);
        }
    };
    const _processImage = async (image) => {
        console.log(post);

        const manipResult = await manipulateAsync(
            image.uri,
            [{ resize: {
                width: IMAGE_WIDTH
            } }],
            { base64: true, compress: IMAGE_COMPRESS, format: SaveFormat.PNG }
        );



        setPost({...post, image: manipResult});
        setReady(true);
    };

    const _renderImage = () => (
      <Image contentFit="contain" source={{ uri: post.image.uri }} style={styles.image} />
    );

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.container}>
            <SeparatorSpace height={5}/>
            <Button variant="primary" icon="camera" onPress={_launchCamera} title="Camera" style={{ alignSelf: 'center' }}/>
            <SeparatorSpace height={5}/>
            <Button variant="primary" icon="images" onPress={_launchMediaLibrary} title="Photo Library" style={{ alignSelf: 'center' }}/> 
           
            <SeparatorSpace height={5}/>

            { ready && post && post.image && _renderImage()}
            <SeparatorSpace height={15}/>
         
        </View>
    </SafeAreaView>)
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'stretch',
      marginTop: StatusBar.currentHeight || 0,
      backgroundColor: '#fff'
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    button: {
      alignItems: "center",
      backgroundColor:'#4b955e',
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: '#fff',
      webkitUserSelect:'none',
      msUserSelect: 'none',
      userSelect: 'none'
    },

    image: {
     flex: 1,
     width: '100%',
    //   paddingLeft: 10,
    //   paddingRight: 10,

      // marginLeft: 10,
      // marginRight: 10
      //backgroundColor: '#0553',
    },

  });
  
export default PostStartScreen;