import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { Platform, StyleSheet, Text, View  } from 'react-native';
import { SeparatorSpace } from '../components/Form';
import Button from '../components/Button';
import { logoutUser } from '../util/authUtil';
import {API_URL} from "../Config"

export default function SettingsScreen({ navigation }) {

  const onLogoutPress = async (e) => {
      await logoutUser(navigation);
  }

  return (
    <View style={styles.container}>
      
      <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
      <Button variant="primary"  onPress={onLogoutPress} title="Logout"/>
      <SeparatorSpace height={100}/>
      <Text>DEBUG INFO BELOW</Text>
      <Text>API_URL: {API_URL}</Text>
    
      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
    </View>
  );
} 

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
