import * as Storage from './storage';
import { WELCOME_SCREEN_NAME } from '../constants/Global';
import {API_URL} from "../Config"


export async function getAuthHeaders () {
    const token = await getSecureToken();
    return {
        authorization: `Bearer ${token}`
    };
}

export async function authFetch (url, fetchObject={}) {
    return new Promise(async (resolve, reject)=> {
        // const token = await getSecureToken();
        // const authHeaders = {
        //     authorization: `Bearer ${token}`
        // };
        const authHeaders = await getAuthHeaders();
        const fetchHeader = {...fetchObject["headers"], ...authHeaders};
        const authedFetchObject = {...fetchObject, headers:fetchHeader};
        let response = null;
        try {
            response = await fetch(url, authedFetchObject);
        } catch (e) {
            reject(response);
            return;
        }
        if (response.status === 401) {
            //return await Storage.setItem('secure_token', 'null');
            //Do we need to do anything here?
            //Probably not, lets have the caller decide what to do with error messages
        }
        resolve(response);
    });
};
export function parseJson(response) {

 
}
export async function userIsAuthenticated() {

    try {
        const response = await authFetch(`${API_URL}/profile/info`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        });

        if (response.status === 200)
            return true;
        
        //Use refresh token to get a new token
        if (response.status === 401) {
            const refreshToken = await getSecureRefreshToken();
            if (refreshToken) {
                const payload = {
                    refreshToken: refreshToken
                }
                const response2 = await fetch(`${API_URL}/token`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(payload),
                });
              
                if (response2.status === 200) {
                    const json = await response2.json();
                    await setSecureToken(json.token);
                    return true;
                }
            }
            return false;
            
        }
        return false;

    } catch (error) {
        console.log(error);
    }
    return false;
}
export async function getSecureToken() {
    return await Storage.getItem('secure_token');
}
export async function getSecureRefreshToken() {
    return await Storage.getItem('secure_refresh_token');
}
export async function setSecureToken(token) {
    await Storage.setItem('secure_token',token);
}
export async function logoutUser(navigation) {

    await Storage.removeItem('secure_token');
    await Storage.removeItem('secure_refresh_token');
    navigation.replace(WELCOME_SCREEN_NAME);
}
export async function setSecureRefreshToken(token) {
    await Storage.setItem('secure_refresh_token',token);
}