import React, { } from 'react';
import { Pressable , Text } from 'react-native';
import Colors from '../constants/Colors';
const HeaderTextButton = ({  title="Next", onPress, size=25, color=Colors.text, disabled=false }) => {
    return <Pressable
        disabled={disabled}
        onPress={onPress}
        style={({ pressed }) => ({
            opacity: pressed ? 0.5 : 1,
        })}>
            <Text size={size} color={color} style={{ marginLeft: "10px", marginRight: "10px", textDecorationLine: 'underline'}}>
                {title}
            </Text>
    </Pressable>
}

export default HeaderTextButton;


