const tintColor = '#2f95dc';
export default {
    text: '#000',
    background: '#fff',
    tint: tintColor,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColor,
    buttonBackground: '#133167',
    buttonBackgroundDelete: 'red',
    blurple: '#635BFF',
    blurple_dark: '#5851DF',
    white: '#FFFFFF',
    light_gray: '#F6F9FC',
    dark_gray: '#425466',
    slate: '#0A2540'
};
