import React, { } from 'react';
import { Text ,StyleSheet, View} from 'react-native';

const ComingSoon = () => {
    return (
    <View style={styles.container}>
        <Text style={styles.title}>Coming Soon....</Text>
    </View>)
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    }
  });
  
export default ComingSoon;