const WELCOME_SCREEN_NAME = 'Welcome'
const HOME_SCREEN_NAME = 'Root'
const LOGIN_SCREEN_NAME = 'Login'
const SIGNUP_SCREEN_NAME = 'SignUp'
const SIGNUP_VERIFY_SCREEN_NAME = 'SignUpVerify'
const PASSWORD_RESET_START_SCREEN_NAME = 'PasswordResetStart'
const PASSWORD_RESET_VERIFY_SCREEN_NAME = 'PasswordResetVerify'
const PASSWORD_RESET_CHANGE_SCREEN_NAME = 'PasswordResetChange'

export {
    WELCOME_SCREEN_NAME , 
    HOME_SCREEN_NAME,
    LOGIN_SCREEN_NAME, 
    SIGNUP_SCREEN_NAME,
    SIGNUP_VERIFY_SCREEN_NAME, 
    PASSWORD_RESET_START_SCREEN_NAME, 
    PASSWORD_RESET_VERIFY_SCREEN_NAME, 
    PASSWORD_RESET_CHANGE_SCREEN_NAME
}