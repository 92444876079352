import React, { useState, useEffect, useCallback } from 'react';
import { SafeAreaView, RefreshControl, StyleSheet, Text, View,FlatList, StatusBar, Pressable  } from 'react-native';
import usePostFetch from '../hooks/usePostFetch';
import { PostContext } from '../context/PostContext';
import useAuthenticatedScreen from '../hooks/useAuthenticatedScreen';
import dayjs from 'dayjs';
import Icon from "react-native-vector-icons/Ionicons";
import {API_URL} from "../Config"
import { FlashList } from "@shopify/flash-list";
import { Image } from 'expo-image';
import sharedStyles from '../components/SharedStyles'; 
//import styles from './PostsScreenStyles'; 

export default function PostsScreen({ navigation }) {

    const [search, setSearch] = useState("");
    const [posts, fetchMore, fetchReset] = usePostFetch('');
    const postContext = React.useContext(PostContext);
    const [isAuthenticated] = useAuthenticatedScreen(navigation);

    useEffect(() => {

        // const unsubscribe = navigation.addListener('focus', async () => {
        //     fetchReset(search);
        // }, []);

        // return unsubscribe;
        fetchReset(search);
    }, [search, postContext.post.last_id]);

    const [refreshing, setRefreshing] = React.useState(false);
    const onRefresh = useCallback(() => {
        setRefreshing(true);
        fetchReset(search);
        setRefreshing(false);
        // setTimeout(() => {
        //   setRefreshing(false);
        // }, 2000);
    }, []);

    const onNextPress = (e) => {
        navigation.navigate('Post');
    }
    const renderItem = ({ item }) => (
        <Post navigation={navigation} post={item}></Post>
      );
    return (
        <SafeAreaView style={sharedStyles.container}>

            {/* <View style={{ flexDirection: "row", alignItems: "center", marginRight: 12}}>
                <View style={styles.searchSection}>
                    <Icon style={styles.searchIcon} name="search" size={20} color="#000"/>
                    <TextInput
                        style={styles.searchInput}
                        onChangeText={setSearch}
                        value={search}
                        placeholder="Search post entries"
                    />
                </View>
                <View>
                    { search.length > 0 && <Pressable 
                        style={styles.searchCancel}
                        onPress={() => setSearch('')}>
                        <Text style={styles.searchCancelText}>Cancel</Text>
                    </Pressable>}
                </View>
            </View> */}
            <View style={{ paddingTop: 10, paddingLeft: 0, flex: 1 }}>
                <FlatList
                            data={posts}
                            estimatedItemSize={150}
                            refreshControl={
                                <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
                            }
                            renderItem={renderItem}
                            keyExtractor={post => post.id}
                            onEndReached={fetchMore}
                            onEndReachedThreshold={0.5}

                            ItemSeparatorComponent={()=>(<View style={{backgroundColor: '#e4e6eb', padding: 8, marginVertical: 0}} ></View>)}
                        />
             </View>
           
        </SafeAreaView>

        );
}
//https://stackoverflow.com/questions/58908710/invariant-violation-requirenativecomponent-fastimageview-was-not-found-in-th
//https://github.com/DylanVann/react-native-fast-image
const Post = ({ navigation, post }) => (
    <Pressable style={{ background: 'white',  flexDirection: 'column', flex: 1, alignItems: 'left' }}  
            onPress={() =>  navigation.navigate('PostView', { id: post.id })}>
        <Text style={{fontSize: 10, fontWeight: 'bold', paddingTop: 10, paddingLeft: 10}}>{dayjs(post.post_date).format('MMM M H:mm A')}</Text>
        <Text style={{fontSize: 10, paddingLeft: 10 }}>{post.place}</Text>
        <Text style={{fontSize: 10, paddingLeft: 10 }}>{post.cuisine}</Text>
        <Image
            style={styles.image}
            source={{uri: API_URL + post.image_url }}
            // placeholder={blurhash}
            contentFit="cover"
            transition={1000}
        />
        {/* <View style={{ padding:10, flexDirection: 'row', flex: 1, alignItems: 'center' }}>
         
        </View> */}
    </Pressable> 
);

const styles = StyleSheet.create({
    container: {
      //maxWidth: '640px', 
      alignSelf: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '640px',
      //minWidth: '320px',

      flex: 1,
      alignItems: 'stretch',
      marginTop: StatusBar.currentHeight || 0,
      backgroundColor: '#fff'
    },
    image: {
            width: '100%',
            height: 320,
            //resizeMode:'cover',
    },
    label: {
        paddingLeft: 10,
        marginTop: 8,
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold'
    },
    searchSection: {
        marginTop: 10,
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f8f9f9',
        borderRadius: 10,
        marginLeft: 20
    },
    searchInput: {
        flex: 1,
        paddingTop: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingLeft: 0,
        color: '#424242'
    },
    searchIcon: {
        padding: 10
    },
    searchCancel: {
        paddingLeft: 10,
        textDecorationLine: 'underline'
    },
    searchCancelText: {
        textDecorationLine: 'underline'
    },
    button: {
        alignItems: "center",
        backgroundColor:'#4b955e',
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 8,
        paddingRight: 8,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#fff',
        webkitUserSelect:'none',
        msUserSelect: 'none',
        userSelect: 'none'
    }
});