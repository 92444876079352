import React, { } from 'react';
import { Pressable, Text, View,StyleSheet} from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import Colors from '../constants/Colors';

const DefaultTabOptions = ({navigation, tabicon=null, headericon=null, title=null, headerTitle=null}) => ({
    headerShown: true,
    headerTitleAlign: 'center',
    headerTitle: () =>(<Text style={styles.title}>{headerTitle || title}</Text>),
    headerRight: () => (<View style={{ 
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 20
        }}><Pressable
        onPress={() => navigation.navigate('Post')}
        style={({ pressed }) => ({
            opacity: pressed ? 0.5 : 1,
        })}>
        <HeaderIcon name="plus"></HeaderIcon>    
        {/* <FontAwesome5
            name="plus"
            size={25}
            color={Colors.text}
            style={{ marginRight: 15 }}
        /> */}
            </Pressable>
    </View>
    ),
        
    tabBarIcon: ({ color }) => <TabBarIcon name={tabicon} color={color} />

})
/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props) {
    return <FontAwesome5 size={25} style={{ marginBottom: -3 }} {...props} />;
}
function HeaderIcon(props) {
    return <FontAwesome5 size={25} style={{ marginBottom: -3, marginRight: 15 }} {...props} />;
}
const styles = StyleSheet.create({
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    }
  });
export default DefaultTabOptions;