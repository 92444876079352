import { StyleSheet, StatusBar  } from 'react-native';

const sharedStyles = StyleSheet.create({
   container: {
     //maxWidth: '640px', 
   //   alignSelf: 'center',
   //   marginLeft: 'auto',
   //   marginRight: 'auto',
   //   width: '220px',
     //minWidth: '320px',

     flex: 1,
     alignItems: 'stretch',
     marginTop: StatusBar.currentHeight || 0,
     backgroundColor: '#fff'
   }
});
export default sharedStyles;