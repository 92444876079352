import React, { useCallback } from 'react';
import Colors from '../constants/Colors';
import { StyleSheet, Pressable, Text, View } from 'react-native';
import PressableOpacity from './PressableOpacity';
import { FontAwesome5 } from '@expo/vector-icons';
const Button = ({ variant='primary', 
title='', 
onPress, 
style = {},
icon,
iconSize=20,
iconColor=Colors.white,
iconStyle={ 
   marginRight: 5,
   borderRadius: 5,
  // color: 'white',
},
titleStyle={},
disabled=false }) => {
    const _style = useCallback(({ pressed }) => ({...styles[ variant + 'Button'], ...style, opacity:pressed ? 0.5 : 1 }));
    const _renderIcon = () => {
        if (icon) {
            return <FontAwesome5 
                    name={icon} 
                    size={iconSize} 
                    color={iconColor} 
                    style={{...styles[variant+'Icon'], ...styles.icon, ...iconStyle }}  />
        }

    }
    const _renderText = () => (<Text style={{...styles[variant+'Title'], ...titleStyle }}>{title}</Text>)
    return (
           
            <Pressable  disabled={disabled} style={_style} onPress={onPress}>
                { icon && _renderIcon() }
                { _renderText() }
            </Pressable>

    )
}

const styles = StyleSheet.create({
    primaryButton: {
        paddingVertical: 8,
        paddingHorizontal: 22,
        borderRadius: 4,
        elevation: 3,
        backgroundColor: '#4b955e',
        flexDirection: 'row',
        //justifyContent: 'flex-start',
        justifyContent: 'center',
        alignItems: 'center',
      },
      primaryTitle: {
        fontSize: 16,
        //lineHeight: 21,
        //fontWeight: 'bold',
        letterSpacing: 0.25,
        color: 'white',
        fontWeight: '600',
        backgroundColor: 'transparent'
      },
    primaryIcon: {
        // marginRight: 15
    },
    secondaryButton: {
        paddingVertical: 8,
        paddingHorizontal: 22,
        borderRadius: 4,
        elevation: 3,
        backgroundColor: '#c7c9cb',
        flexDirection: 'row',
        //justifyContent: 'flex-start',
        justifyContent: 'center',
        alignItems: 'center',
    },
    secondaryTitle: {
        fontSize: 16,
        color: '#fff',
        textAlign: 'center',
        fontWeight: '600',
        backgroundColor: 'transparent'
    },
    secondaryIcon: {
       // marginRight: 15
    },
    offsetButton: {
        paddingVertical: 8,
        paddingHorizontal: 22,
        borderRadius: 4,
        elevation: 3,
        backgroundColor: 'transparent',
        flexDirection: 'row',
        //justifyContent: 'flex-start',
        justifyContent: 'center',
        alignItems: 'center',
    },
    offsetTitle: {
        fontSize: 16,
        color: '#133167',
        textAlign: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: 'transparent'
    },
    offsetIcon: {
      
    }
});
export default Button;