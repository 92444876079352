import React, { useEffect,useState } from 'react';
import { Platform, StyleSheet, Text, SafeAreaView, View, TextInput } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Button from '../components/Button';
import { LOGIN_SCREEN_NAME } from '../constants/Global';
import {API_URL} from "../Config"

export default function SignUpVerifyScreen({ navigation }) {

    const [message,setMessage] = useState(null);
    const [code, setCode] = useState('');
    const onSubmitPress = async ()=> {

        if (code <= 0) return setMessage("Code is required field");
        const payload = {
            "code": code
        };
        try {
            const response = await fetch(`${API_URL}/signup-finish`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            const json = await response.json();
            if (response.status === 200) {
                navigation.replace(LOGIN_SCREEN_NAME);
            } else {
                setMessage(json.message || "unable to verify the code");
            }
           
        } catch (error) {
            console.log(error);
            setMessage("unable to complete verification");
        };
    }

    return (
      <SafeAreaView style={styles.container}>
 
        <View style={styles.container}>
            <Text style={styles.title}>Sign Up</Text>
            <TextInput
                style={styles.input}
                placeholder="Enter verification code"
                onChangeText={(text) => setCode(text)}
                value={code}
            />
          
           <Button variant="primary" onPress={onSubmitPress} title="Submit"/>

           <View style={{ alignSelf: 'center'}}>
            
                <Text>Already have an account?</Text>
            </View>
            <Text style={styles.error}>{message}</Text>
        </View>
        <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
      </SafeAreaView>
    );
}


const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
    },
    title: {
      fontSize: 24,
      marginBottom: 16,
    },
    input: {
      width: '100%',
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
      borderRadius: 4,
      marginBottom: 16,
      paddingHorizontal: 8,
    },
    error: {
         color: 'red'
    }
  });

  
