import React, { useState, useEffect, useCallback } from 'react';
import { SafeAreaView, StyleSheet,StatusBar } from 'react-native';
import useAuthenticatedScreen from '../hooks/useAuthenticatedScreen';
import usePostFetch from '../hooks/usePostFetch';
import { PostContext } from '../context/PostContext';

//Issue with expo and react-native-maps for web view
//https://github.com/expo/web-examples/issues/73
//https://docs.expo.dev/guides/customizing-webpack/

// import MapView, { PROVIDER_GOOGLE } from 'react-native-maps';

export default function ExploreScreen({ navigation }) {
    const [isAuthenticated] = useAuthenticatedScreen(navigation);
    const [posts, fetchMore, fetchReset] = usePostFetch('');
    const postContext = React.useContext(PostContext);
    useEffect(() => {

        // const unsubscribe = navigation.addListener('focus', async () => {
        //     fetchReset(search);
        // }, []);

        // return unsubscribe;
        fetchReset();
    }, [postContext.post.last_id]);
    return (
        <SafeAreaView style={styles.container}>
                { posts.map((post) => (
                    <div>

                        { post.place_latitude } { post.place_longitude }
                    </div>
                    // <Marker
                    // key={index}
                    // coordinate={{latitude: post.place_latitude, longitude: post.place_longitude}}
                    // title={marker.title}
                    // description={marker.description}
                    // />
                ))}

             {/* <MapView provider={PROVIDER_GOOGLE} style={styles.map} /> */}
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      //alignItems: 'stretch',
     //marginTop: StatusBar.currentHeight || 0,
      //backgroundColor: '#fff'
    },
    map: {
        width: '100%',
        height: '100%',
    }
});