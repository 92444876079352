/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';
const linking = {
    prefixes: [Linking.createURL('/')],
    config: {
        screens: {
            Welcome: 'welcome',
            Root: {
                screens: {
                    Posts: 'posts',
                    Explore: 'explore',
                    Post: 'post'
                },
            },
            Login: 'login',
            SignUp: 'signup',
            Settings: 'setting',
            PostStart: 'post-start',
            PostPlace: 'post-place',
            PostFinish: 'post-finish',
            PostView: 'post-view'
        },
    },
};

 export default linking;
 