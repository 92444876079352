import React from 'react';
import { Text, View,Pressable ,TouchableOpacity } from 'react-native';
import { NavigationContainer} from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import PostsScreen from '../screens/PostsScreen'
import PostStartScreen from '../screens/PostStartScreen'
import PostPlaceScreen from '../screens/PostPlaceScreen'
import PostFinishScreen from '../screens/PostFinishScreen'
import PostViewScreen from '../screens/PostViewScreen'
import ExploreScreen from '../screens/ExploreScreen'
import LoginScreen from '../screens/LoginScreen'
import SignUpScreen from '../screens/SignUpScreen'
import SignUpVerifyScreen from '../screens/SignUpVerifyScreen'
import WelcomeScreen from '../screens/WelcomeScreen'
import SettingsScreen from '../screens/SettingsScreen'
import PasswordResetStartScreen from '../screens/PasswordResetStartScreen'
import PasswordResetVerifyScreen from '../screens/PasswordResetVerifyScreen'
import PasswordResetChangeScreen from '../screens/PasswordResetChangeScreen'
import DefaultTabOptions from './DefaultTabOptions'
import LinkingConfiguration from './LinkingConfiguration';
import Colors from '../constants/Colors';
import { FontAwesome5 } from '@expo/vector-icons';
import HeaderIconButton from '../components/HeaderIconButton';
import HeaderTextButton from '../components/HeaderTextButton';
import BackArrow from '../components/BackArrow';
import { HOME_SCREEN_NAME } from '../constants/Global';

export default function Navigation() {
    return (
      <NavigationContainer linking={LinkingConfiguration}>
        <RootNavigator />
      </NavigationContainer>
    );
}

const Stack = createNativeStackNavigator();
function RootNavigator() {
  
  return (
    <Stack.Navigator initialRouteName="Welcome"> 
        <Stack.Screen options={{headerShown: false}} name="Welcome" component={WelcomeScreen}/>
        <Stack.Screen name="Root" component={BottomTabNavigator} options={{ headerShown: false }} />
        <Stack.Screen options={{headerShown: true}} name="SignUp" component={SignUpScreen}/>
        <Stack.Screen options={{headerShown: true}} name="SignUpVerify" component={SignUpVerifyScreen}/>
        <Stack.Screen options={{headerShown: true, title:null }} name="Login"  component={LoginScreen}/>

        <Stack.Screen options={{headerShown: true}} name="PostView" component={PostViewScreen}/>
        <Stack.Screen options={{headerShown: true}} name="PasswordResetStart" component={PasswordResetStartScreen}/>
        <Stack.Screen options={{headerShown: true}} name="PasswordResetVerify" component={PasswordResetVerifyScreen}/>
        <Stack.Screen options={{headerShown: true}} name="PasswordResetChange" component={PasswordResetChangeScreen}/>
        <Stack.Screen
            name="Post"
            component={PostStartScreen}
            options={({ navigation, route  }) => (
              {
                title: 'Post Image',
                headerShown: true,
                headerTitleAlign: 'center',
                tabBarIcon: ({ color }) => <TabBarIcon name="plus" color={color} />,
                headerLeft: () => (<HeaderTextButton title="Cancel" size={25} onPress={() => navigation.navigate(HOME_SCREEN_NAME)} />),
              })
            }
        />
        <Stack.Screen
            name="PostPlace"
            component={PostPlaceScreen}
            options={({ navigation, route  }) => (
              {
                title: 'Where was this meal at?',
                headerShown: true,
                headerTitleAlign: 'center',
                tabBarIcon: ({ color }) => <TabBarIcon name="plus" color={color} />,
                headerLeft: () => (<HeaderTextButton title="Cancel" size={25} onPress={() => navigation.navigate(HOME_SCREEN_NAME) } />),
              })
          }
        />


        <Stack.Screen
            name="PostFinish"
            component={PostFinishScreen}
            options={({ navigation, route  }) => (
              {
                headerShown: true,
                headerTitleAlign: 'center',
                title: 'New Post',
                
                tabBarIcon: ({ color }) => <TabBarIcon name="plus" color={color} />,
                headerLeft: () => (<HeaderTextButton title="Cancel" size={25} onPress={() => navigation.navigate(HOME_SCREEN_NAME)} />)
            })
          }
        />
        {/* <Stack.Screen options={{headerShown: true}} name="PostFinish" component={PostFinishScreen}/> */}
       <Stack.Group screenOptions={{ presentation: 'modal' }}></Stack.Group>
    </Stack.Navigator>
  );
}

const Tab = createBottomTabNavigator();

function BottomTabNavigator() {
  return (
    <Tab.Navigator initialRouteName="Posts" 
      screenOptions={{ tabBarActiveTintColor: Colors.tint}} 
        // TODO:  tabBar={props => <MyTabBar {...props} />}
    >
        <Tab.Screen
            name="Posts"
            component={PostsScreen}
            options={({ navigation }) => (
              DefaultTabOptions({navigation, title:"My Post", headerTitle: "My Food Journal", headericon:"utensils",tabicon:"utensils"})
            )}
        />

        <Tab.Screen
            name="Explore"
            component={ExploreScreen}
            options={({ navigation }) => (
              DefaultTabOptions({navigation, title:"Explore", headericon:"map-marker-alt",tabicon:"map-marker-alt"})
            )}
        />
         
        <Tab.Screen
            name="Settings"
            component={SettingsScreen}
            options={({ navigation }) => (
              DefaultTabOptions({navigation, title:"Settings", headericon:"cog",tabicon:"cog"})
            )}
        />
        {/* <Tab.Screen options={({ navigation }) => ({
                    headerLeft: () => (
                    <Pressable
                        // onPress={() => navigation.canGoBack() ? navigation.goBack() : navigation.navigate(HOME_SCREEN_NAME)}
                        onPress={() =>navigation.goBack()}
                        style={({ pressed }) => ({
                            opacity: pressed ? 0.5 : 1,
                        })}>
                        <FontAwesome5
                            name="times"
                            size={25}
                            color={Colors.text}
                            style={{ marginRight: 15 }}s
                        />
                    </Pressable>
                    )
                })} name="Settings" component={SettingsScreen} /> */}
     
    </Tab.Navigator>
  );
}
 
/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props) {
    return <FontAwesome5 size={25} style={{ marginBottom: -3 }} {...props} />;
}
function HeaderIcon(props) {
  return <FontAwesome5 size={25} style={{ marginBottom: -3 }} {...props} />;
}