import React, { useEffect,useState } from 'react';
import { Platform, StyleSheet, Text, SafeAreaView, View, TextInput } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Button from '../components/Button';
import { SeparatorSpace } from '../components/Form';
import { setSecureToken , setSecureRefreshToken} from '../util/authUtil';
import { PASSWORD_RESET_VERIFY_SCREEN_NAME } from '../constants/Global';
import {API_URL} from "../Config"

export default function PasswordResetStartScreen({ navigation, route }) {

    const [message,setMessage] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSubmitPress = async ()=> {

        //if (email.length <= 0) return setMessage("E-mail is required field");
     
        const payload = {
            "email": email
        };
        try {
            const response = await fetch(`${API_URL}/password-reset-start`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            const json = await response.json();
            if (response.status === 200) {
              navigation.navigate(PASSWORD_RESET_VERIFY_SCREEN_NAME);
            } else {
                setMessage(json.message || "error please contact site adminstrator");
            }
           
        } catch (error) {
            console.log(error);
            setMessage("error please contact site adminstrator");
        };
    }

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.container}>
          
            <Text style={styles.title}>Forgot Password</Text>
            <TextInput
                style={styles.input}
                placeholder="Email"
                onChangeText={(text) => setEmail(text)}
                value={email}
            />
            <Text style={styles.caption}>Type in your email address to reset your password.</Text>
            <SeparatorSpace height={25}/>
           <Button variant="primary" onPress={onSubmitPress} title="NEXT"/>
           <Text style={styles.error}>{message}</Text>
        </View>

        {/* Use a light status bar on iOS to account for the black space above the modal */}
        <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
      </SafeAreaView>
    );
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
    },
    title: {
      fontSize: 24,
      marginBottom: 16,
    },
    caption: {
      fontSize: 14
    },
    input: {
      width: '100%',
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
      borderRadius: 4,
      marginBottom: 16,
      paddingHorizontal: 8,
    },
    error: {
         color: 'red'
    }
  });

  
