import React, { useEffect,useState } from 'react';
import { Platform, StyleSheet, Text, SafeAreaView, View, TextInput, Pressable } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Button from '../components/Button';
import { SeparatorSpace } from '../components/Form';
import { setSecureToken , setSecureRefreshToken} from '../util/authUtil';
import { HOME_SCREEN_NAME, SIGNUP_SCREEN_NAME, PASSWORD_RESET_START_SCREEN_NAME } from '../constants/Global';
import {API_URL} from "../Config"

export default function LoginScreen({ navigation, route }) {

    const [message,setMessage] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const onSignUpPress = (e) => {
        navigation.navigate(SIGNUP_SCREEN_NAME);
    }
    const onForgotPasswordPress = (e) => {
        navigation.navigate(PASSWORD_RESET_START_SCREEN_NAME);
    }
    const onSignInPress = async ()=> {


        setIsLoggingIn(true);
        // if (email.length <= 0) return setMessage("E-mail is required field");
        // if (password.length <= 0) return setMessage("Password is a required field");
        const payload = {
            "email": email,
            "password" : password
        };
        try {
            const response = await fetch(`${API_URL}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            const json = await response.json();

            if (response.status === 200) {
                await setSecureToken(json.token);
                await setSecureRefreshToken(json.refreshToken);
                navigation.replace(HOME_SCREEN_NAME);
            } else {
                setMessage(json.message || "unable to login");
            }
           
        } catch (error) {
            console.log(error);
            setMessage("unable to login");
        };
        setIsLoggingIn(false);
    }

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.container}>
          
            <Text style={styles.title}>Login</Text>
            <SeparatorSpace height={15}/>
            <TextInput
                style={styles.input}
                placeholder="Email address"
                onChangeText={(text) => setEmail(text)}
                value={email}
            />
            <TextInput
                style={styles.input}
                placeholder="Password"
                secureTextEntry
                onChangeText={(text) => setPassword(text)}
                value={password}
            />
           <Button variant="primary" onPress={onSignInPress} disabled={isLoggingIn} title="LOGIN"/>
           <SeparatorSpace height={5}/>
           <Pressable onPress={onForgotPasswordPress}>
                <Text style={{ alignSelf: 'center'}}>
                Forgot password &gt;
                </Text>
            </Pressable>
           <SeparatorSpace height={15}/>
           <View style={{ alignSelf: 'center'}}>
                <Text>Don't have an account? </Text>
            </View>
            <View style={{ alignSelf: 'center'}}>
                <Button variant="offset" onPress={onSignUpPress} title="SIGN UP"/>
            </View>
            {/* <Text>API_URL: {API_URL}</Text> */}
            <Text style={styles.error}>{message}</Text>
        </View>

        {/* Use a light status bar on iOS to account for the black space above the modal */}
        <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
      </SafeAreaView>
    );
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      padding: 32,
    },
    title: {
      fontSize: 24,
      marginBottom: 16,
      alignSelf: 'center'
    },
    input: {
      width: '100%',
      height: 50,
      borderColor: 'gray',
      borderWidth: 1,
      borderRadius: 4,
      marginBottom: 16,
      paddingHorizontal: 8,
    },
    error: {
         color: 'red'
    }
  });

  
