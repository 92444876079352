import React, { useEffect,useState } from 'react';
import { Platform, StyleSheet, Text, SafeAreaView, View, TextInput } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Button from '../components/Button';
import { authFetch } from '../util/authUtil'
import { LOGIN_SCREEN_NAME } from '../constants/Global';
import {API_URL} from "../Config"

export default function PasswordResetChangeScreen({ navigation, route }) {

    const [message,setMessage] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');

    const onSubmitPress = async ()=> {

        if (!newPassword || !confirmedPassword )
            return setMessage("Password must be entered twice");
        if (!(newPassword === confirmedPassword))   
            return setMessage("Passwords must must match");

        const payload = {
                "newPassword": newPassword,
                "confirmedPassword": confirmedPassword
        };

        try {

            const response = await authFetch(`${API_URL}/password-reset-change`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            const json = await response.json();

            if (response.status === 200) {
                navigation.replace(LOGIN_SCREEN_NAME);
            } else  {
                setMessage(json.message);
            }
           
        } catch (error) {
            console.log(error);
            setMessage("error please contact site adminstrator");
        };
    }

    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.container}>
          
            <Text style={styles.title}>Enter New Password</Text>
            <TextInput
                style={styles.input}
                placeholder="New Password"
                secureTextEntry
                onChangeText={(text) => setNewPassword(text)}
                value={newPassword}
            />
           <Text style={styles.title}>Confirm New Password</Text>
           <TextInput
                style={styles.input}
                placeholder="Confirm New Password"
                secureTextEntry
                onChangeText={(text) => setConfirmedPassword(text)}
                value={confirmedPassword}
            />
          
           <Button variant="primary" onPress={onSubmitPress} title="SUBMIT"/>
           <Text style={styles.error}>{message}</Text>
        </View>

        {/* Use a light status bar on iOS to account for the black space above the modal */}
        <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
      </SafeAreaView>
    );
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 16,
    },
    title: {
      fontSize: 24,
      marginBottom: 16,
    },
    input: {
      width: '100%',
      height: 40,
      borderColor: 'gray',
      borderWidth: 1,
      borderRadius: 4,
      marginBottom: 16,
      paddingHorizontal: 8,
    },
    error: {
         color: 'red'
    }
  });

  
