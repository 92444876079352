import React, { useEffect, useState } from 'react';
import { SafeAreaView, StyleSheet, Text, View, StatusBar } from 'react-native';
import { Image } from 'expo-image';
import Button from '../components/Button';
import { authFetch } from '../util/authUtil'
import {API_URL} from "../Config"
import dayjs from 'dayjs';

async function fetchPost(id) {
    const response = await authFetch(`${API_URL}/post/${id}`);
    const data = await response.json();
    return data;
}

const PostViewScreen = ({ navigation, route }) => {
    const [post, setPost] = useState({
        id: null,
        post_date:null,
        cuisine: null,
        place: null,
        image_url:null
    });
    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', async () => {
            const data = await fetchPost(route.params.id);
            console.log(data);

            setPost(data);

           // setShowAlert(false);
           // setAlertMessage("");
        }, [navigation]);

        return unsubscribe;
    }, [route.params.id]);
    return (
        <SafeAreaView style={styles.container}>
        <View style={styles.container}>
            <Text style={{fontSize: 10, fontWeight: 'bold', paddingTop: 10, paddingLeft: 10}}>{dayjs(post.post_date).format('MMM M H:mm A')}</Text>
            <Text style={{fontSize: 10, paddingLeft: 10 }}>{post.place}</Text>
            <Text style={{fontSize: 10, paddingLeft: 10 }}>{post.cuisine}</Text>
            <Text style={{fontSize: 10, paddingLeft: 10 }}>{post.comments}</Text>
            <Image style={styles.image} 
           
             contentFit="cover"
             transition={1000} source={{uri: API_URL + post.image_url }}/>
       
        </View>
    </SafeAreaView>)
}
const styles = StyleSheet.create({
    container: {
      flex: 1,
      alignItems: 'stretch',
      marginTop: StatusBar.currentHeight || 0,
      backgroundColor: '#fff'
    },
    title: {
      fontSize: 20,
      fontWeight: 'bold',
    },
    button: {
      alignItems: "center",
      backgroundColor:'#4b955e',
      paddingTop: 12,
      paddingBottom: 12,
      paddingLeft: 8,
      paddingRight: 8,
      borderRadius: 8,
      borderWidth: 1,
      borderColor: '#fff',
      webkitUserSelect:'none',
      msUserSelect: 'none',
      userSelect: 'none'
    },
    image: {

      width: '100%',
       height: '100%'
    },
  });
  
export default PostViewScreen;