import React, { useEffect, useState, useCallback } from 'react';
import { authFetch } from '../util/authUtil'
import {API_URL} from "../Config"

// see:  https://medium.com/@srbkrishnan/infinite-scroll-pagination-in-flatlist-with-hooks-and-function-components-c9c08bba23a8

const maxRowsPerFetch = 5;
async function fetchPosts(keyword, page, limit = 10) {
  try {
    const response = await authFetch(`${API_URL}/explore/search?keyword=${keyword}&page=${page}&limit=${limit}`);
    const result = await response.json();
    if (!Array.isArray(result.data)) {
        return [];
    }
    return result.data;
  } catch (e) {
    console.log(e);
    return [];
  }
}

export default function usePostFetch() {
    const [page, setPage] = useState(1);
    const [shouldFetch, setShouldFetch] = useState(true);
    const [posts, setPosts] = useState([]);
    const [keyword, setKeyword] = useState('');

    const fetchMore = useCallback(() => setShouldFetch(true), []);
    const fetchReset = useCallback((newKeyword='') => {
        setPosts([]);
        setShouldFetch(true);
        setPage(1);
        setKeyword(newKeyword);
    }, []);

    useEffect(() => {
        if (!shouldFetch) {
          return;
        }
  
        const fetch = async () => {
          const newPosts = await fetchPosts(keyword, page, maxRowsPerFetch);
          setShouldFetch(false);
          setPosts(oldPosts => [...oldPosts, ...newPosts]);
          setPage(page + 1);
        };
  
        fetch();
      },[page, shouldFetch]);
  
    return [posts, fetchMore, fetchReset];
}