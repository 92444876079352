import React, { useEffect,useState } from 'react';
import { Platform, StyleSheet, Text, SafeAreaView, View, TextInput } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Button from '../components/Button';
import { SeparatorSpace } from '../components/Form';
import { SIGNUP_VERIFY_SCREEN_NAME } from '../constants/Global';
import {API_URL} from "../Config"


export default function SignUpScreen({ navigation }) {

    const [message,setMessage] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);

    const onSignInPress = (e) => {
        navigation.replace('Login');
    }

    const onSignUpPress = async ()=> {
        setIsSigningIn(true);
        if (email.length <= 0) return setMessage("E-mail is required field");
        if (password.length <= 0) return setMessage("Password is a required field");
        if (firstname.length <= 0) return setMessage("First name is a required field");
        if (lastname.length <= 0) return setMessage("Last name is a required field");

        const payload = {
            "email": email,
            "password" : password,
            "firstname" : firstname,
            "lastname" : lastname
        };
        try {
            const response = await fetch(`${API_URL}/signup-start`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            const json = await response.json();
            if (response.status === 200) {
                navigation.navigate(SIGNUP_VERIFY_SCREEN_NAME);
           } else {
                setMessage(json.message);
           }
           
        } catch (error) {
            console.log(error);
            setMessage("unable to sign up");
        };
        setIsSigningIn(false);
    }

    return (
      <SafeAreaView style={styles.container}>
 
        <View style={styles.container}>
            <Text style={styles.title}>Sign Up</Text>
            <SeparatorSpace height={15}/>
            <TextInput
                style={styles.input}
                placeholder="Email address"
                onChangeText={(text) => setEmail(text)}
                value={email}
            />
            <TextInput
                style={styles.input}
                placeholder="Password"
                secureTextEntry
                onChangeText={(text) => setPassword(text)}
                value={password}
            />
            <TextInput
                style={styles.input}
                placeholder="First name"
                onChangeText={(text) => setFirstname(text)}
                value={firstname}
            />
                     <TextInput
                style={styles.input}
                placeholder="Last name"
                onChangeText={(text) => setLastname(text)}
                value={lastname}
            />
           <Button variant="primary" onPress={onSignUpPress} disabled={isSigningIn} title="SIGN UP"/>
           <SeparatorSpace height={15}/>
           <View style={{ alignSelf: 'center'}}>
            
                <Text>Already have an account?</Text>
            </View>
            <View style={{ alignSelf: 'center'}}>
                <Button variant="offset" onPress={onSignInPress} title="LOGIN"/>
            </View>
            <SeparatorSpace height={15}/>
            <Text style={styles.error}>{message}</Text>
        </View>

        
        
        {/* Use a light status bar on iOS to account for the black space above the modal */}
        <StatusBar style={Platform.OS === 'ios' ? 'light' : 'auto'} />
      </SafeAreaView>
    );
}


const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
    //   alignItems: 'center',
      padding: 16,
    },
    title: {
        fontSize: 24,
        marginBottom: 16,
        alignSelf: 'center'
    },
    input: {
        width: '100%',
        height: 50,
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 4,
        marginBottom: 16,
        paddingHorizontal: 8
      },
    error: {
         color: 'red'
    }
  });

  
