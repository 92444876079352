import React, { useState, useEffect } from 'react';
import { userIsAuthenticated } from '../util/authUtil';
import { WELCOME_SCREEN_NAME } from '../constants/Global';

export default function useAuthenticatedScreen(navigation) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async () => {
        const isAuth = await userIsAuthenticated();
        setIsAuthenticated(isAuth);
        if (!isAuth) {
            navigation.navigate(WELCOME_SCREEN_NAME);
        }
    }, []);
    return unsubscribe;
  }, []);

  return [isAuthenticated];
}
